import { useState } from 'react'
import emailjs from 'emailjs-com'
import { FaMobileAlt, FaPhoneAlt, FaYoutube } from "react-icons/fa";

const initialState = {
  name: '',
  lname:'',
  phone:'',
  email: '',
  message: '',
}

export const Contact = (props) => {
  const [{ name,lname,phone,email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                {/* <p style={{color:'#fff'}}>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p> */}
              </div>
              <form name='sentMessage' 
               action="https://formspree.io/f/meqwalpn" method="POST" >
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='lname'
                        name='lname'
                        className='form-control'
                        placeholder='Last Name'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Phone'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg' value="send">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-5 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3 style={{color:'#fff'}}>Contact Info</h3>
              <p>
                <span style={{color:'#fff'}}>
                  <i className='fa fa-map-marker' style={{color:'#fff'}}></i> Address
                </span>
                <p style={{color:'#fff'}}>{props.data ? props.data.address : 'loading'}</p>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span style={{color:'#fff'}}>
                  <i className='fa fa-phone' style={{color:'#fff'}} ></i> Phone
                </span>{' '}
                <p style={{color:'#fff'}}>{props.data ? props.data.phone : 'loading'}</p>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span style={{color:'#fff'}}>
                  <i className='fa fa-envelope-o'  style={{color:'#fff'}}></i> Email
                </span>{' '}
                <p style={{color:'#fff'}}>{props.data ? props.data.email : 'loading'}</p>
              </p>
            </div>

            
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>

    <a href="tel:9755646411">
        <div className='stickyPhone'>
          <FaPhoneAlt className="icons" color="#fff" size={20}/>
        </div>
      </a>

      </div>
    </div>
    
  )
}
