export const Products = (props) => {
    return (

      <section id="services-6" className="bg-blue wide-40 servicess-section division">
		<div className="container white-color">	

			<div className="row sbox-6">	
				<div className="col-lg-12 offset-lg-1 section-title">		

					<h2>Ferrous Sulphate Crystal & Sugar Crystale</h2>	
								
				</div> 
			</div>
			

			<div className="row d-flex align-items-center">

				<div className="col-md-6 col-lg-4">
					
					{/* <div className="sbox-6 pr-30 text-right wow fadeInRight" data-wow-delay="0.4s">
					
						<h4 className="h5-md">Water Heat</h4>
						<p>The crystals of ferrous sulphate on heating give ferric oxide, sulphur dioxide and sulphur trioxide.</p>
						<p>Diagnosis, prevention and correction of 
							malpositioned teeth and jaws through braces which gives healthy teeth and better smile.</p>
						
					</div> */}
							
					<div className="sbox-6 pr-30 mr-30 text-right wow fadeInRight" data-wow-delay="0.6s">							
							
						<h4 className="h5-sm">Pharmacy</h4>
						<p>This is a medicine used to treat and prevent iron deficiency anaemia. </p>
						{/* <p>Restoration and replacement of lost or damaged teeth by 
							taking support from neighbouring teeth and forming a bridge.</p> */}
						
					</div>	

					<div className="sbox-6 pr-30 text-right wow fadeInRight" data-wow-delay="0.8s">							
						
						<h4 className="h5-md">Fertilizer</h4>
						<p>FERROUS SULPHATE contains iron micronutrient is useful for the complete growth of plants.</p>
						{/* <p>A titanium screw acting like a artificial root placed in the Jaw bone in the missing tooth region to provide actual teeth like 
							experience for better esthetics and functions.</p> */}
					</div>

					{/* <div className="sbox-6 pl-30 wow text-right fadeInRight" data-wow-delay="0.4s">
						<h4 className="h5-md">Root Canal</h4>
						<p>Root canal is a treatment to repair and save a badly damaged...</p>
						 <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> 
						
					</div> */}
						
				</div>	

				<div className="col-lg-4 s6-img">	
					<div className="sbox-6-img mb-40 text-center wow fadeInUp" data-wow-delay="0.8s">
						<img className="img-fluid" src="img/sbox.png" alt="services-image" style={{width:370}}/>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">	

					<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.4s">
						
						<h4 className="h5-md">Cotton Industry</h4>
						<p>Laser dentistry is the use of lasers to treat a number of different dental... </p>
						{/* <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> */}
						
					</div>
					
					<div className="sbox-6 pl-30 ml-30 wow fadeInLeft" data-wow-delay="0.6s">
					
						<h4 className="h5-md">Dyes</h4>
						<p>This is used alone as a mordant, as a color shifter and to increase lightfastness when used in combination with other natural dyes.</p>
						{/* <p>Root canal treatment helps save a tooth that is infected and completely decayed, 
							relieves toothache and gives better tooth functioning.</p> */}
					</div>
						
				{/*	<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.8s">
						
						<h4 className="h5-md">Others</h4>
						<p>Ferrous sulfate (or sulphate) is a medicine used to treat and prevent iron deficiency anaemia. </p> 
						 <p>Cleaning of teeth and root surfaces to remove yellow deposits i.e. 
							plaque and calculus. Keeps gums healthy avoiding bad breath, 
							bleeding gums and tooth mobility.</p>
					</div>	

					<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.4s">
{/* 						
						<h4 className="h5-md">Tooth Replacement Solutions</h4>
						<p>If you lose a single tooth, a dental implant is an excellent solution...</p> */}
						{/* <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> 
					</div>*/}
						
						
				</div>

			</div>

			{/* <div className="row">	
				<div className="col-lg-10 offset-lg-1 section-title">
					<Link to="/treatments" className="btn tra-white-hover btn-orange ">View All Services</Link> 
				</div> 
			</div> */}

		</div>	
</section>


      // <div id='services' className='text-center'>
      //     <div className='container'>
      //       <div className='section-title'>
      //           <h2>Our Products</h2>
      //       </div>
      //       <div className='row'>
      //       {props.data
      //           ? props.data.map((d, i) => (
      //               <div key={`${d.title}-${i}`} className='col-xs-12 col-md-6'>
      //             {' '}
      //             <div className="product-card">
      //               <img src={d.img} alt='' className='img-responsive product-img'/>
      //               <h3 className="product-title">{d.title}</h3>
      //               <p>{d.text}</p>
      //             </div>
      //           </div>
                
      //           ))
      //           : 'loading'}
      //       </div>
      // </div>
      // </div>
    )
  }
  