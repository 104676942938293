import ParticlesBg from "particles-bg";

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        {/* <ParticlesBg type="circle" bg={{zIndex: 0, position:"absolute", top:0}} /> */}
       
        <div className='overlay'>
        {/* <img className="img-fluid" src="img/banner.jpeg" alt="services-image" style={{width:'100%'}}/> */}
          <div className='container'> 
          
            <div className='row'>
              <div className='col-md-8 intro-text'>
                <div className="mobile-header"> </div>
                    <h1>
                      {props.data ? props.data.title : 'Loading'}
                      <span></span>
                    </h1>
                    <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                    {/* <a
                      href='#features'
                      className='btn btn-custom btn-lg page-scroll'
                    >
                      Learn More
                    </a>{' '} */}
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
